html, body, #root {
    position: relative;
    height: 100%;
}

.t-layout {
  min-height: 100vh
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  /*padding: 5px 10px 0 10px;*/
}


.login-form {
  margin: auto;
  width: 30%;
  margin-top: 10%;
}

@media all and (min-width: 1024px) and (max-width: 1280px) {
    .login-form {
      margin: auto;
      width: 30%;
      margin-top: 10%;
    }
 }

@media all and (min-width: 618px) and (max-width: 1024px) { 
  .login-form {
    margin: auto;
    width: 40%;
    margin-top: 10%;
  }
}

@media all and (min-width: 480px) and (max-width: 618px) { 
  .login-form {
    margin: auto;
    width: 50%;
    margin-top: 10%;
  }
}

@media all and (max-width: 480px) {
  .login-form {
    margin: auto;
    width: 80%;
    margin-top: 10%;
  }
  .screen-title {
    display: none !important;
  }
 }
