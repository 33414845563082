.layout {
  text-align: center;
}

.content {
  min-height: 80vh;
}

.footer {
  padding: 12px;
}