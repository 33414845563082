/* Extra large devices (large laptops and desktops, 1280px and up) */
@media (min-width: 1690px) {

  .dashboard-grid {
    grid-template-columns: repeat(5, minmax(0, 1fr))
  }

}

/* Large devices (laptops/desktops, 1024px and up) */
@media (min-width: 1024px) and (max-width: 1689px) {

  .dashboard-grid {
    grid-template-columns: repeat(3, minmax(0, 1fr))
  }

}

/* Medium devices (landscape tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 1023px) {

  .dashboard-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

}



/* Small devices (portrait tablets and large phones, 640px and up) */
@media (min-width: 640px) and (max-width: 767px) {

  .dashboard-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

}


/* Extra small devices (phones, 600px and down) */
@media (max-width: 639px) {

  .dashboard-grid {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

}
